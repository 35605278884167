<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select week</label>
              <input type="week" class="form-control" v-model="selectedWeek" @change="onWeekChanged">
            </div>
          </div>
        </div>
      </div>

      <template v-if="payout">
        <template v-if="payout['payoutSummary']">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <div class="row">
                  <div class="col-md-3">
                    <div class="py-1">
                      <!--                <i class="fa fa-users fa-2x text-info"></i>-->
                      <h3 class="text-info"> {{payout['payoutSummary']['total_number_drivers_for_cost']}} </h3>
                      <p class="text-uppercase mb-1 font-13 font-weight-medium">
                        Total Drivers
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="py-1">
                      <!--                <i class="fa fa-user-shield fa-2x text-warning"></i>-->
                      <h3 class="text-warning"> {{payout['payoutSummary']['total_cost'] | toCurrencyFormat }} </h3>
                      <p class="text-uppercase mb-1 font-13 font-weight-medium">
                        Total Cost
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="py-1">
                      <h3 class="text-success"> {{payout['payoutSummary']['total_income'] | toCurrencyFormat}} </h3>
                      <p class="text-uppercase mb-1 font-13 font-weight-medium">
                        Total Income
                      </p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="py-1">
                      <h3 class="text-success"> {{payout['totalBankTransfer'] | toCurrencyFormat}} </h3>
                      <p class="text-uppercase mb-1 font-13 font-weight-medium">
                        Transfer to bank ({{payout['bankTransferCountDrivers']}})
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </template>

        <!--        <b-card class="" >-->
        <!--          -->
        <!--        </b-card>-->
      </template>
      <template v-else>
        <div class="card">
          <div class="card-body">
            <div class="">
              <h4 class="card-title text-danger">There are no approved payouts for {{selectedWeek}}</h4>
            </div>
          </div>
        </div>
      </template>

      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" />
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <!--           /////////////////////// CONTENT HERE -->
            <template v-if="driver">
              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">{{driver['name']}} {{driver['other_names']}}</span>
                  <template v-if="driver['prospective_driver'] && driver['prospective_driver']['service_type']">
                    <span class="badge badge-dark p-1"> {{driver['prospective_driver']['service_type'] | serviceType}}</span>
                  </template>
                  <template v-else>
                    (No service selected)
                  </template>

                  <span class="ml-2">|</span>
                  <span class="ml-3">Current accumulated arrears = {{accumulatedArrears | toCurrencyFormat}}</span>

                </h5>
              </div>
              <hr>

             <template v-if="numberOfPayouts > 1">

               <div class="alert alert-info">
                 {{numberOfPayoutDisplay}}
               </div>

               <hr>


             </template>
              <!--              content here-->
              <template v-if="payoutDetails.length > 0">

                <template v-for="(detail, index) in payoutDetails">

                  <b-card :key="detail['id']">

                    <h4 class="card-title"> Payout made on {{ detail['dateCreated'] }} </h4>

                    <b-card>
                      <h5 class="card-title"> Cost breakdown </h5>
                      <table class="table table-striped">
                        <tr>
                          <td>Total Cost</td>
                          <td class="text-right">{{detail['selectedWeekTotalCost'] | toCurrencyFormat}}</td>
                        </tr>
                      </table>
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>Field name</th>
                          <th>Name displayed to driver</th>
                          <th>Field value</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="cb in detail['costBreakDown']">
                          <tr>
                            <td>{{cb['fieldName']}}</td>
                            <td>{{cb['fieldNameDisplay']}}</td>
                            <td>{{cb['fieldValue']}}</td>
                          </tr>
                        </template>
                        </tbody>

                      </table>
                    </b-card>
                    <hr>
                    <b-card>
                      <h5 class="card-title"> Arrears as at payout </h5>
                      <table class="table table-striped">
                        <tr>
                          <td>As at {{selectedWeek}}</td>
                          <template v-if="detail['arrearsAfterPayout']">
                            <td class="text-danger text-right">{{detail['arrearsAfterPayout'] | toCurrencyFormat}}</td>
                          </template>
                          <template v-else>
                            <td> N/A </td>
                          </template>

                        </tr>

                        <tr>
                          <td>As at previous week</td>
                          <template v-if="detail['arrearsBeforePayout']">
                            <td class="text-danger text-right">{{detail['arrearsBeforePayout'] | toCurrencyFormat}}</td>
                          </template>
                          <template v-else>
                            <td> N/A </td>
                          </template>

                        </tr>

                      </table>
                    </b-card>
                      <hr>
                    <b-card>
                      <h5 class="card-title"> Income breakdown </h5>
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>Provider</th>
                          <th>Total field name</th>
                          <th>Total value</th>
                          <th>Calculated field value</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="payload in (detail['incomePayload'] ? JSON.parse(detail['incomePayload']) : [] )">
                          <tr>
                            <td>{{payload['name']}}</td>
                            <td>{{payload['totalFieldName']}}</td>
                            <template v-if="payload['hasTotalField']">
                              <td><span class="text-success">{{payload['totalFieldValue']}}</span></td>
                            </template>
                            <template v-else>
                              <td>N/A</td>
                            </template>
                            <td>
                              <template v-if="payload['hasTotalField']">
                                <span>{{payload['calculatedIncome']}}</span>
                              </template>
                              <template v-else>
                                <span class="text-success">{{payload['calculatedIncome']}}</span>
                              </template>

                            </td>

                          </tr>
                        </template>
                        </tbody>
                        <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->

                      </table>
                    </b-card>
                    <b-card>
                      <h5 class="card-title"> Balance for {{selectedWeek}} </h5>
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>Total income for {{selectedWeek}}</th>
                          <th>Total cost for {{selectedWeek}}</th>
                          <th>Balance for {{selectedWeek}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{detail['selectedWeekTotalIncome'] | toCurrencyFormat}}</td>
                          <td>{{detail['selectedWeekTotalCost'] | toCurrencyFormat }}</td>
                          <td>{{detail['selectedWeekBalance'] | toCurrencyFormat}}</td>
                        </tr>
                        </tbody>
                        <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->
                      </table>
                      <h5 class="card-title"> Money to be transferred to bank on {{selectedWeek}} </h5>
                      <table class="table">
                        <tr>
                          <td>Transferred to bank</td>
                          <template v-if="detail['transferToBank'] > 0">
                            <td class="text-success">{{detail['transferToBank'] | toCurrencyFormat}}</td>
                          </template>
                          <template v-else>
                            <td class="text-danger">{{detail['transferToBank'] | toCurrencyFormat}}</td>
                          </template>

                        </tr>
                      </table>
                    </b-card>

                      <!--                Scheduled payments from payment plan of this week -->
                      <template v-if="detail['payoutPaymentPlans'].length > 0">
                        <hr>
                        <h5 class="card-title"> Payment schedule for this week </h5>
                        <small>This is the amount the driver has to pay for this week according to a previously scheduled payment plan</small>
                        <b-card>
                          <table class="table">
                            <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Week in which this plan was created</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="payoutPlan in detail['payoutPaymentPlans']">
                              <tr>
                                <td> {{payoutPlan['amount']}} </td>
                                <td> {{payoutPlan['from_week']}} </td>
                              </tr>
                            </template>
                            </tbody>

                          </table>
                        </b-card>
                      </template>

<!--                      Create payment plan-->
<!--                      <template v-if="(detail['selectedWeekTotalIncome'] - detail['selectedWeekTotalCost']) < 0.00 && detail['paymentPlanSpread'] <= 0">-->
<!--                        <hr>-->
<!--                        <h5>Set payment plan for {{selectedWeek}}</h5>-->
<!--                        <small>You are seeing this because {{driver['name']}}' cost for {{selectedWeek}} was greater than his income</small>-->
<!--                        <b-card>-->
<!--                          <form action="#" @submit.prevent="submitCreatePaymentPlan">-->
<!--                            <div class="row">-->
<!--                              <div class="col-md-6">-->
<!--                                <div class="form-group">-->
<!--                                  <label>Spread (specify in weeks) eg. 3</label>-->
<!--                                  <input type="number" class="form-control" v-model="paymentPlanInWeeksForm.spread" required>-->
<!--                                  <small>this is the number of weeks to spread payment plan over. Starting from next week</small>-->
<!--                                </div>-->
<!--                                <div class="form-group">-->
<!--                                  <button type="submit" disabled class="btn btn-dark"><i class="fas fa-check-circle"></i> submit </button>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </form>-->

<!--                        </b-card>-->
<!--                      </template>-->

<!--                      Get created payment plans -->
                      <template v-if="detail['createdPaymentPlan']">
                        <h5>Payment plan created for {{selectedWeek}}</h5>
                        <small>This is the payment plan administrator created for this payout</small>
                        <b-card>
                          <table class="table">
                            <thead>
                            <tr>
                              <th>Amount to pay</th>
                              <th>Spread in weeks</th>
                              <th>Starting from</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>{{detail['createdPaymentPlan']['total_amount_to_pay']}}</td>
                              <td>{{detail['createdPaymentPlan']['spread']}}</td>
                              <td>{{detail['createdPaymentPlan']['startingFrom']}}</td>
                            </tr>
                            </tbody>

                          </table>
                        </b-card>
                      </template>



                  </b-card>


                </template>

              </template>

            </template>
            <!--           /////////////////////// END OF CONTENT HERE -->

          </div >

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->

      <div class="row" v-if="payout">
        <div class="col-md-12">
          <b-card class="text-center">
            <button class="btn btn-success mr-2" @click="resendPayoutPushNotifications"> <i class="fas fa-check-circle"></i> Resend notifications for week {{selectedWeek}} </button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {payoutService} from "../../../../apis/payout.service";
import Sidepanel from "./sidepanel";
import {Skeleton} from "vue-loading-skeleton";
import {financialAdministrationService} from "../../../../apis/financial.administration.service";
import moment from "moment";
import {confirm} from "../../../../utils/functions";

export default {
  name: "make-payout",
  components: {
    Sidepanel,
    Skeleton
  },
  data(){
    return  {
      driver: null,
      selectedWeek: '',
      data: null,
      selectedIncomeCostType: '',
      accumulatedArrears: '0.00',
      paymentPlanInWeeksForm: {
        spread: ''
      },
      payout: null,
      paymentPlanInWeeks: null,
      payoutDetails: [],
      numberOfPayouts: 0,
      numberOfPayoutDisplay: '',
      totalBankTransfer: 0.00,
    }
  },
  methods: {

    onDriverSelected(driver){
      console.log('driver: ', driver);
      this.driver = driver;
    },


    getPreviousWeekInHtmlFormat(){
      const year = moment().year();
      const week = moment().week();
      return  year + '-W' + week.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      // console.log('weekInHtmlFormat',weekInHtmlFormat)
    },

    getGeneratedPayout(){
      if(!this.driver){
        return;
      }
      if(this.selectedWeek === ''){
        return;
      }

      const payload = {
        'week': this.selectedWeek,
        'driver_user_id': this.driver['id'],
        'status': 'approved'
      }

      this.$store.dispatch('showLoader')
      payoutService.getPayoutHistory(payload).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        const p = data['extra']['payoutSummary'] ? data['extra'] : null;
        this.payout = {...p};
        this.payoutDetails = data['extra']['payoutDetails'];
        this.numberOfPayoutDisplay = data['extra']['numberOfPayoutDisplay']
        this.numberOfPayouts = data['extra']['numberOfPayouts']
        this.totalBankTransfer = data['extra']['totalBankTransfer']
        console.log('payout', this.payout)

      })

    },

    // getPaymentPlanInWeeks() {
    //
    //   if(!this.driver){
    //     return;
    //   }
    //   if(this.selectedWeek === ''){
    //     return;
    //   }
    //
    //   const payload = {
    //     'week': this.selectedWeek,
    //     'driverUserId': this.driver['id'],
    //   }
    //
    //   this.$store.dispatch('showLoader')
    //   payoutService.getPaymentPlanInWeeks(payload).then((data) => {
    //     this.$store.dispatch('hideLoader')
    //
    //     if(!data.status){
    //       this.$store.dispatch('error', {message:data.message, showSwal: true})
    //       return;
    //     }
    //
    //     this.paymentPlanInWeeks = data['extra']
    //     console.log('paymentPlanInWeeks',this.paymentPlanInWeeks);
    //
    //   })
    //
    //
    // },

    submitCreatePaymentPlan(){

      if (this.payout == null){
        return;
      }

      confirm('Create payment plan', () => {
        const payload = {
          'payoutDetailId': this.payout['payoutDetail']['id'],
          'spread': this.paymentPlanInWeeksForm.spread,
        }

        this.$store.dispatch('showLoader')
        payoutService.createPaymentPlan(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message:data.message, showSwal: true})

        })
      })


    },

    resendPayoutPushNotifications(){

      if (this.payout == null){
        return;
      }

      confirm('Send out notifications', () => {

        this.$store.dispatch('showLoader')
        payoutService.resendGeneratePayoutNotifications(this.payout['payoutSummary']['id']).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message:data.message, showSwal: true})

        })
      })


    },




    getArrearsForDriver(){
      if(!this.driver){
        return;
      }

      this.$store.dispatch('showLoader')
      payoutService.fetchArrears(this.driver['id']).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        this.accumulatedArrears = data['extra']['accumulated_amount'];

      })

    },

    onWeekChanged(){
      this.getGeneratedPayout();
      // this.getPaymentPlanInWeeks();
    },

    deleteGeneratedPayout(payoutId){

      confirm('Delete payout for ' + this.selectedWeek , () => {
        this.$store.dispatch('showLoader')
        payoutService.deleteGeneratePayout(payoutId).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})
          this.getGeneratedPayout();

        })
      })

    },




  },
  watch: {
    driver: function (driver) {
      if(driver){
        this.getArrearsForDriver();
        this.getGeneratedPayout();
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle','Payout history');
    this.selectedWeek = this.getPreviousWeekInHtmlFormat();
    // this.fetchPayoutStanding()
  }
}
</script>

<style scoped>

</style>